@charset "utf-8"

@import "../../styles/lib/mixins.sass"
@import "../../styles/lib/variables.sass"

.cs-row
  display: flex
  flex-direction: row
  margin-left: calc((col-gap() / 2) * -1)
  margin-right: calc((col-gap() / 2) * -1)
  &.is-row-reverse
    flex-direction: row-reverse
  &.is-column
    flex-direction: column
  &.is-column-reverse
    flex-direction: column-reverse
  &.no-gap
    margin-left: 0
    margin-right: 0
    // &.is-first
    // &.is-last
  &.is-multiline
    flex-wrap: wrap
  &.is-centered
    justify-content: center
  &.is-vcentered
    align-items: center
  &.is-visible
    display: flex
  &.is-hidden
    display: none

+md
  .cs-row
    margin-left: calc((col-gap('md') / 2) * -1)
    margin-right: calc((col-gap('md') / 2) * -1)
    &.no-gap,
    &.no-gap-md
      margin-left: 0
      margin-right: 0
      // &.is-first
      // &.is-last
    &.is-multiline-md
      flex-wrap: wrap
    &.is-centered-md
      justify-content: center
    &.is-vcentered-md
      align-items: center

+sm
  .cs-row
    margin-left: calc((col-gap('sm') / 2) * -1)
    margin-right: calc((col-gap('sm') / 2) * -1)
    &.no-gap,
    &.no-gap-sm
      margin-left: 0
      margin-right: 0
      // &.is-first
      // &.is-last
    &.is-multiline-sm
      flex-wrap: wrap
    &.is-centered-sm
      justify-content: center
    &.is-vcentered-sm
      align-items: center

+xs
  .cs-row
    margin-left: calc((col-gap('xs') / 2) * -1)
    margin-right: calc((col-gap('xs') / 2) * -1)
    &:not(.is-xs)
      flex-wrap: wrap
      &.no-gap,
      &.no-gap-xs
        margin-left: 0
        margin-right: 0
        // &.is-first
        // &.is-last
    &.is-xs
      display: flex
      &.no-gap,
      &.no-gap-xs
        margin-left: 0
        margin-right: 0
        // &.is-first
        // &.is-last
    &.is-multiline-xs
      flex-wrap: wrap
    &.is-centered-xs
      justify-content: center
    &.is-vcentered-xs
      align-items: center
