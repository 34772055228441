@charset "utf-8"

@import "../../styles/lib/mixins.sass"
@import "../../styles/lib/variables.sass"

.cs-sections
  .section
    margin-top: m(2)
    &:first-child
      margin-top: 0
    &.images
      .col
        padding: col-gap()
        background-color: grey(8)
      &.is-slider
        .col
          padding-left: 0
          padding-right: 0
          background-color: transparent
    &.links
      ul
        &.is-buttons
          display: flex
          justify-content: center
          flex-wrap: wrap
          li
            margin: m(1)
        &.is-list
          list-style: disc inside none
          margin-left: m(2)

+md
  .cs-sections
    .section
      &.images
        .col
          padding: col-gap('md')

+sm
  .cs-sections
    .section
      margin-top: m(3)
      &:first-child
        margin-top: 0
      &.images
        .col
          padding: col-gap('sm')

+xs
  .cs-sections
    .section
      margin-top: m(2)
      &.images
        .col
          padding: col-gap('xs')
