@charset "utf-8"

@import "../../styles/lib/mixins.sass"
@import "../../styles/lib/variables.sass"

.cs-base
  z-index: $z-index-base
  .page
    padding-top: 0
    padding-bottom: 0
    transition: all .4s cubic-bezier(.55,0,.1,1)
    &.fade-enter-active,
    &.fade-leave-active
      transition: opacity .5s ease
    &.fade-enter,
    &.fade-leave-active
      opacity: 0
