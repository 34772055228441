@charset "utf-8"

@import "../../styles/lib/mixins.sass"
@import "../../styles/lib/variables.sass"


// every color-class or fill is ignored, when svg has fill-attribute
// (which is intended to have multi-colored svgs)
.cs-svg
  height: auto
  fill-rule: evenodd
  clip-rule: evenodd
  stroke-linejoin: round
  stroke-miterlimit: 1.41421
  fill: grey(5)
  &.is-primary
    fill: color('primary')
  &.is-secondary
    fill: color('secondary')
  &.is-warning
    fill: color('warning')
  &.is-success
    fill: color('success')
  &.is-white
    fill: white()
