@charset "utf-8"

@import "../../styles/lib/mixins.sass"
@import "../../styles/lib/variables.sass"

.cs-link
  cursor: pointer
  &.has-button
    text-decoration: none !important
    cursor: default
