@charset "utf-8"

@import "../../styles/lib/mixins.sass"
@import "../../styles/lib/variables.sass"

$dot-color: grey(7)
$dot-active-color: color('primary')
$dot-width: 14px
$dot-height: 14px

.cs-images
  position: relative
  display: block
  overflow: hidden
  margin-top: calc((col-gap()/2) * -1)
  margin-bottom: calc((col-gap()/2) * -1)
  .slide
    display: flex
    flex-direction: column
    position: relative
    padding: calc(col-gap()/2) !important
    justify-content: center
    align-items: center
    line-height: 0
    .caption
      padding: .3rem 0
      line-height: line-height('default')
  .images-carousel // unique name, otherwise cs-image-zoom will inherit
    .VueCarousel
      margin: 0
      padding: 0
      .VueCarousel-wrapper
        .VueCarousel-inner
          margin-left: calc((col-gap() / 2) * -1)
          margin-right: calc((col-gap() / 2) * -1)
    .navigation
      display: none
      position: absolute
      top: calc(col-gap()/2)
      bottom: calc(col-gap()/2) // col-gap() + (1.5 * $dot-height)
      width: m(5)
      .button
        height: 100%
        width: 100%
        &.is-disabled
          //display: none
      &.prev
        left: 0
        .button
          justify-content: flex-start
      &.next
        right: 0
        .button
          justify-content: flex-end
  .images-pagination
    position: relative
    text-align: center
    line-height: 0
    font-size: 0
    overflow: hidden
    .pagination
      position: absolute
      top: -9999px // invisible, but in DOM, don't change, set to 0 if visible
      padding-top: calc($dot-height / 2) // pixel required, @see navigation bottom
      padding-bottom: m(1)
      display: inline-flex
      .dot
        flex-shrink: 0
        margin: 0 3px
        width: $dot-width
        height: $dot-height
        background-color: $dot-color
        cursor: pointer
        border-radius: 7px
        &:first-child
          margin-left: 0
        &:last-child
          margin-right: 0
        &:not(.is-active):hover
          background-color: darken($dot-color, 20%)
        &.is-active
          background-color: $dot-active-color
          cursor: default
  .images-legend
    display: flex
    justify-content: space-between
    align-items: center
  &.has-navigation
    .images-carousel
      .navigation
        display: block
  &.has-pagination
    .images-pagination
      .pagination
        position: relative
        top: 0
  &.is-pagination-inside
    .images-carousel
      .navigation
        bottom: calc(col-gap()/2)
    .images-pagination
      width: 100%
      transform: translateY(- m(5))
      margin-bottom: - $dot-height
      .pagination
        padding: 0 m(1)
        .dot
          background-color: white()
          opacity: .6
          &:not(.is-active):hover
            background-color: white()
            opacity: 1
          &.is-active
            background-color: $dot-active-color
            opacity: 1
  &.has-zoom
    .slide
      .frame
        position: relative
        cursor: pointer
        overflow: hidden
        &:after
          display: flex
          justify-content: center
          align-items: center
          content: map-get($icons, 'fullscreen')
          position: absolute
          left: 0
          top: 0
          width: 100%
          height: 100%
          font-family: $font-family-icons
          font-size: font-size('big')
          color: white()
          background-color: black()
          opacity: 0
          transition: opacity .6s ease-out .4s
        picture
          img
            transition: all .6s ease-out .4s
        &:hover
          &:after
            opacity: .4
          picture
            img
              transform: scale(1.1)
    .images-carousel
      position: relative

+md
  .cs-images
    margin-top: calc((col-gap('md')/2) * -1)
    margin-bottom: calc((col-gap('md')/2) * -1)
    .slide
      padding: calc(col-gap('md')/2) !important
    .images-carousel
      .VueCarousel
        .VueCarousel-inner
          margin-left: calc((col-gap('md') / 2) * -1)
          margin-right: calc((col-gap('md') / 2) * -1)
      .navigation
        top: calc(col-gap('md')/2)
        bottom: col-gap('md') + (1.5 * $dot-height)
    &.is-pagination-inside
      .images-carousel
        .navigation
          bottom: calc(col-gap('md')/2)

+sm
  .cs-images
    margin-top: calc((col-gap('sm')/2) * -1)
    margin-bottom: calc((col-gap('sm')/2) * -1)
    .slide
      padding: calc(col-gap('sm')/2) !important
    .images-carousel
      .VueCarousel
        .VueCarousel-inner
          margin-left: calc((col-gap('sm') / 2) * -1)
          margin-right: calc((col-gap('sm') / 2) * -1)
      .navigation
        top: calc(col-gap('sm')/2)
        bottom: col-gap('sm') + (1.5 * $dot-height)
    &.is-pagination-inside
      .images-carousel
        .navigation
          bottom: calc(col-gap('sm')/2)

+xs
  .cs-images
    margin-top: calc((col-gap('xs')/2) * -1)
    margin-bottom: calc((col-gap('xs')/2) * -1)
    .slide
      padding: calc(col-gap('xs')/2) !important
    .images-carousel
      .VueCarousel
        .VueCarousel-inner
          margin-left: calc((col-gap('xs') / 2) * -1)
          margin-right: calc((col-gap('xs') / 2) * -1)
      .navigation
        top: calc(col-gap('xs')/2)
        bottom: col-gap('xs') + (1.5 * $dot-height)
    .images-legend
      flex-direction: column
    &.is-pagination-inside
      .images-carousel
        .navigation
          bottom: calc(col-gap('xs')/2)
