@charset "utf-8"

@import "../../styles/lib/mixins.sass"
@import "../../styles/lib/variables.sass"

.cs-container
  margin: 0 auto
  padding: 0 content-padding()
  width: content-width()
  max-width: 100%
  &.is-fullwidth
    width: 100%
    padding-right: 0
    padding-left: 0

+md
  .cs-container
    padding: 0 content-padding('md')
    width: content-width('md')
    &.is-fullwidth
      width: 100%
      padding-right: 0
      padding-left: 0

+sm
  .cs-container
    padding: 0 content-padding('sm')
    width: content-width('sm')
    &.is-fullwidth
      width: 100%
      padding-right: 0
      padding-left: 0

+xs
  .cs-container
    padding: 0 content-padding('xs')
    width: content-width('xs')
    &.is-fullwidth
      width: 100%
      padding-right: 0
      padding-left: 0
