@charset "utf-8"

@import "../../styles/lib/mixins.sass"
@import "../../styles/lib/variables.sass"

.cs-image
  // no display: flex !!
  font-size: 0
  line-height: 0
  &.is-hero // parent must have required height!
    display: flex
    flex-direction: row
    width: 100%
    height: 100%
    overflow: hidden
    img
      flex-grow: 1
      font-family: "object-fit:cover;object-position:50% 50%"
      object-fit: cover
      object-position: 50% 50%
      width: 100%
      height: auto
