@charset "utf-8"

@import "../../styles/lib/mixins.sass"
@import "../../styles/lib/variables.sass"

// https://tobiasahlin.com/spinkit/
.cs-spinner
  visibility: hidden
  margin: 0 auto
  width: 70px // set width to control dimension
  text-align: center
  animation: 1s spinner-fadein
  animation-fill-mode: forwards
  animation-delay: .4s
  div
    width: 33.333%
    height: 0
    padding-top: 33.333%
    background-color: color('primary')
    border-radius: 100%
    display: inline-block
    animation: spinner-bouncedelay 1.4s infinite ease-in-out both
    animation-delay: -1.16s
    &:first-child
      animation-delay: -1.32s
    &:last-child
      animation-delay: -1s
  &.has-delay
    animation-delay: 1.4s
    div
      animation-delay: -0.16s
      &:first-child
        animation-delay: -0.32s
      &:last-child
        animation-delay: 0s
  &.is-primary
    div
      background-color: color('primary')
  &.is-secondary
    div
      background-color: color('primary')
  &.is-warning
    div
      background-color: color('warning')
  &.is-success
    div
      background-color: color('success')
  &.is-white
    div
      background-color: white()

@keyframes spinner-fadein
  0%
    opacity: 0
  100%
    visibility: visible
    opacity: 1

@keyframes spinner-bouncedelay
  0%, 80%, 100%
    transform: scale(0)
  40%
    transform: scale(1.0)
